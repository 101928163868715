import React, { useState, useEffect } from "react"
import Head from "../../components/head"
import {
  Card,
  Typography,
  CardActions,
  Button,
} from "@material-ui/core"
import {navigate} from 'gatsby';
import { styled } from '@material-ui/core/styles';
import Layout from "../../components/layout"
import Footer from "../../components/footer";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Header from "../../components/header";

const iktstrlv = () => {
  
  
  const [pageReady,setPageReady] = useState(false)
  const ThankyouText = styled(Typography)({
    fontSize: '4rem',
    fontWeight: 100,
    margin: '0 auto'
  });
  const DownloadButton = styled(Button)({
    margin: '16px auto'
  });
  useEffect(() => {
    //let paymentFinishedCheck = document.referrer;
    //if (paymentFinishedCheck.indexOf('https://checkout.stripe.com/pay/') !== 0) {
    //  navigate('/')
    //} else {
      setPageReady(true)
    //}
  }, [])
  return (
    <Layout>
      <Head />
      <Header/>
      <div className="centerWrap">
        {
          pageReady && (
            <div>
            <ThankyouText>Thank you!</ThankyouText>
              <Typography>投げ銭ありがとうございます。引き続き応援をよろしくお願いいたします！</Typography>
            </div>
          )
        }
        
      </div>
      <Footer/>
    </Layout>
  )
}

export default iktstrlv
